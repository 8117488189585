<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12 class="pa-2">
        <v-simple-table v-if="$vuetify.breakpoint.lgAndUp" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left fonte">
                  PRODUTO
                </th>
                <th class="text-left fonte">
                  VALOR
                </th>
                <th class="text-left fonte">
                  USUÁRIO
                </th>
                <th class="text-left fonte">
                  STATUS
                </th>
                <th class="text-left fonte">
                  DATA DA COMPRA
                </th>
                <th class="text-left fonte">
                  DATA DE APROVAÇÃO
                </th>
                <th class="text-left fonte">
                  DATA DE ATIVAÇÃO
                </th>
                <th class="text-left fonte"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in getPurchases"
                :key="item._id"
                :style="`background: ${index % 2 === 0 ? '#f1f1f1' : ''}`"
              >
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{ item.item.description }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold green--text"
                >
                  {{ $helper.formataSaldo(item.item.price) }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{ item.user.name }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{ item.current_status }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{
                    moment(item.due_date)
                      .subtract(4, "days")
                      .format("DD/MM/YYYY HH:mm")
                  }}
                </td>
                <td
                  @click="$router.push(`/purchases-view/${item._id}`)"
                  class="font-weight-bold"
                >
                  {{ moment(item.due_date).format("DD/MM/YYYY HH:mm") }}
                </td>
                <td>
                  <v-btn
                    icon
                    @click="
                      createConfirmAction({
                        icon: 'mdi-delete',
                        message: 'Deseja remover esta compra?',
                        action: 'deletePurchase',
                        action_value: item
                      })
                    "
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list
          v-else
          dark
          style="border-radius: 6px;"
          class="pa-0 ma-0"
          dense
          three-line
        >
          <template v-for="(item, index) in getPurchases">
            <v-list-item :key="item._id">
              <v-list-item-content
                @click="$router.push(`/purchases-view/${item._id}`)"
              >
                <v-list-item-title class="fonte">
                  Cliente: {{ item.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="fonte">
                  {{ item.item.description }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte orange--text">
                  Status: {{ item.current_status }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="fonte">
                  Data Compra:
                  {{ moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    createConfirmAction({
                      icon: 'mdi-delete',
                      message: 'Deseja remover esta compra?',
                      action: 'deletePurchase',
                      action_value: item
                    })
                  "
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-flex>
      <!-- <template v-for="purchase in getPurchases">
        <PurchaseCareer :key="purchase.item._id" :purchase="purchase" v-if="purchase.purchase_type==='career'" />
        <PurchaseInvestment :key="purchase.item._id" :purchase="purchase" v-if="purchase.purchase_type==='investment'" />
        <PurchaseProduct :key="purchase.item._id" :purchase="purchase" v-if="purchase.purchase_type==='product'" />
      </template> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PurchaseCareer from "./components/PurchaseCareer";
import PurchaseInvestment from "./components/PurchaseInvestment";
import PurchaseProduct from "./components/PurchaseProduct";
import moment from "moment";
export default {
  data() {
    return {
      moment: moment
    };
  },
  components: {
    PurchaseCareer,
    PurchaseInvestment,
    PurchaseProduct
  },
  computed: {
    ...mapGetters(["getPurchases"])
  },
  methods: {
    ...mapActions(["listPurchases", "createConfirmAction"])
  },
  created() {
    this.listPurchases();
  }
};
</script>
